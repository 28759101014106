/* eslint-disable react/require-default-props */
import React from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { SendOutlined } from '@ant-design/icons'

function Send({
  icon = <SendOutlined />,
  type = 'success',
  title = 'button.send',
  onClick = () => {},
  id = 'btn-send',
  ...props
}) {
  const { formatMessage } = useIntl()
  const buttonTitle = title === 'button.send' ? formatMessage({ id: title }) : title

  return (
    <Button
      disabled={props.loading}
      {...props}
      title={buttonTitle}
      onClick={onClick}
      icon={icon}
      type={type}
      id={id}
    >
      {buttonTitle}
    </Button>
  )
}

Send.propTypes = {
  icon: PropTypes.object,
  type: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
}

export default Send
